module.exports = [{
      plugin: require('../../../node_modules/gatsby-plugin-gatsby-cloud/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Laptopaxs","short_name":"Laptopaxs","start_url":"/","background_color":"#fff","theme_color":"#F6D341","display":"standalone","icon":"src/images/icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"b2754d70eda4f36cea6566b1127af044"},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://laptopaxs.com","stripQueryString":true},
    },{
      plugin: require('../../../node_modules/gatsby-remark-autolink-headers/gatsby-browser.js'),
      options: {"plugins":[],"offsetY":0,"className":"anchor"},
    },{
      plugin: require('../../../packages/gatsby-theme-seomass/gatsby-browser.js'),
      options: {"plugins":[],"siteMetadata":{"siteTitle":"Laptopaxs","siteDescription":"Compare the top laptops!","siteUrl":"https://laptopaxs.com","siteSubject":"Laptops","siteSubjectSlug":"laptops","siteLanguage":"en","siteImage":"/banner.jpg","siteTheme":{"mainColor":"#F6D341","secondColor":"#7d6b21","thirdColor":"#493f13"},"siteNavigation":[{"name":"Laptops","slug":"/laptops/","submenu":"categories"},{"name":"Blog","slug":"/blog/"},{"name":"About","isCustomPage":true,"slug":"/about/","staticSubMenu":[{"name":"Contact us","slug":"/contact/"}]}]}},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-windicss/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
